export const section_content = [
  {
    uid: "Home",
    title: " Hello, \n I’m Duarte ",
    button: "What I do?"
  },
  {
    uid: "What I do",
    title: " I design and code \n JavaScript ",
    button: "My approach?"
  },
  {
    uid: "My Approach",
    title: " I like making cool \n apps ",
    button: "More"
  },
  {
    uid: "Contact",
    title: "Get in touch",
    button: "Back to Top"
  }
]

export const social_medias = [
  {
    id: 'twitter',
    title: 'Twitter',
    url: 'http://twitter.com/du5rte',
    color: '#00aced',
    path: 'M88 24.3c-2.3 3.2-5.1 6-8.3 8.3 0 .5.1 1.2.1 2.1 0 4.3-.7 8.6-2 12.9-1.3 4.3-3.3 8.4-5.9 12.3-2.7 3.9-5.8 7.4-9.5 10.4s-8.1 5.4-13.3 7.2c-5.2 1.8-10.7 2.7-16.6 2.7-9.3 0-17.8-2.4-25.5-7.2 1.2.1 2.5.2 4 .2 7.7 0 14.6-2.3 20.6-6.8-3.6-.1-6.8-1.1-9.7-3.2-2.8-2.1-4.8-4.7-5.9-7.9 1.1.2 2.2.2 3.1.2 1.5 0 2.9-.2 4.4-.5-3.8-.8-7-2.6-9.5-5.5s-3.8-6.3-3.8-10.2V39c2.3 1.3 4.8 1.9 7.5 2-2.3-1.5-4.1-3.4-5.4-5.7-1.3-2.3-2-4.9-2-7.6 0-2.9.8-5.6 2.3-8.1 4.2 4.9 9.2 8.9 15.2 11.8 6 3 12.3 4.6 19.1 4.9-.3-1.3-.4-2.5-.4-3.7 0-4.4 1.6-8.2 4.9-11.3 3.2-3.1 7.2-4.7 11.8-4.7 4.8 0 8.9 1.7 12.1 5.1 3.7-.7 7.3-2 10.6-3.9-1.3 3.8-3.7 6.7-7.3 8.8 3.1-.2 6.3-1 9.4-2.3z'
  },
  {
    id: 'behance',
    title: 'Behance',
    url: 'http://behance.net/du5rte',
    color: '#1769FF',
    path: 'M7.5 22.2h25.2c2.5 0 4.7.2 6.6.6s3.7 1 5.4 1.9 2.9 2.2 3.8 3.9 1.3 3.8 1.3 6.3c0 4.9-2.4 8.5-7.3 10.8 3.2.9 5.7 2.4 7.3 4.7 1.6 2.3 2.5 5 2.5 8.4 0 2-.4 3.9-1 5.6-.7 1.7-1.6 3.1-2.8 4.2-1.2 1.1-2.6 2.1-4.2 2.9-1.6.8-3.3 1.4-5.1 1.7-1.8.4-3.7.5-5.7.5h-26V22.2zm11.7 20.9h11.9c2.2 0 4-.5 5.2-1.5 1.3-1 1.9-2.5 1.9-4.6 0-3.9-2.7-5.9-8.1-5.9h-11v12zm0 22h12.6c5.8 0 8.7-2.3 8.7-6.8 0-4.9-2.8-7.4-8.5-7.4H19.2v14.2zm56.8 2c1.8 0 3.5-.4 5.2-1.3 1.7-.9 2.7-2.1 3.2-3.6h9.4c-2.8 8.4-8.9 12.6-18.1 12.6-6.1 0-10.9-1.8-14.5-5.4-3.6-3.6-5.4-8.3-5.4-14.2 0-5.7 1.8-10.4 5.5-14.1 3.7-3.8 8.5-5.6 14.3-5.6 3.9 0 7.3.9 10.2 2.8 2.9 1.9 5.1 4.3 6.5 7.3 1.4 3 2.1 6.4 2.1 10.2 0 .5 0 1.1-.1 1.9h-28c0 3 .8 5.4 2.4 7 1.8 1.6 4.2 2.4 7.3 2.4zm10-41.4H64.3v5.1H86v-5.1zM75.3 43.2c-2.5 0-4.6.7-6.2 2.1-1.6 1.4-2.5 3.4-2.6 5.8h17.3c-.5-5.3-3.4-7.9-8.5-7.9z'
  },
  {
    id: 'codepen',
    title: 'CodePen',
    url: 'http://codepen.io/du5rte',
    color: '#fcd000',
    path: 'M93.7 34.6v26.9c0 1.3-.6 2.4-1.7 3.1L50.2 91.4c-.7.4-1.4.6-2.2.6-.7 0-1.5-.2-2.2-.6L4 64.6c-1.2-.8-1.7-1.8-1.7-3.1V34.6c0-1.3.6-2.4 1.7-3.1L45.8 4.6c.7-.4 1.4-.6 2.2-.6.7 0 1.5.2 2.2.6L92 31.4c1.2.8 1.7 1.8 1.7 3.2zM10.1 54.3L20 48l-9.9-6.3v12.6zM27 43.4l17.1-11V14.8L13.3 34.6 27 43.4zm-13.7 18l30.8 19.8V63.5L27 52.6l-13.7 8.8zM48 57l13.9-9L48 39l-13.9 9L48 57zm21-13.6l13.7-8.9-30.8-19.7v17.7L69 43.4zM51.9 81.2l30.8-19.8L69 52.6l-17.1 11v17.6zM76 48l9.9 6.3V41.7L76 48z'
  },
  {
    id: 'dribbble',
    title: 'dribbble',
    url: 'https://dribbble.com/du5rte',
    color: '#ea4c89',
    path: 'M48 87C26.4 87 8.9 69.5 8.9 48S26.4 9 48 9s39.1 17.5 39.1 39S69.6 87 48 87zm33-33.7c-1.1-.4-10.3-3.1-20.8-1.4 4.4 12 6.1 21.8 6.5 23.8 7.5-5.1 12.8-13.1 14.3-22.4zM61 78.8c-.5-2.9-2.4-13.1-7.1-25.3-.1 0-.1 0-.2.1-18.8 6.6-25.6 19.6-26.2 20.8 5.7 4.4 12.8 7 20.5 7 4.6 0 9-.9 13-2.6zm-37.8-8.4c.8-1.3 9.9-16.4 27.1-22 .4-.1.9-.3 1.3-.4-.8-1.9-1.8-3.8-2.7-5.7-16.7 5-32.9 4.8-34.3 4.7v1c0 8.6 3.3 16.4 8.6 22.4zm-7.9-29.2c1.5 0 15.3.1 30.9-4.1-5.5-9.8-11.5-18.1-12.4-19.3-9.3 4.5-16.3 13.1-18.5 23.4zm24.9-25.6c.9 1.2 7 9.5 12.5 19.5 11.9-4.4 16.9-11.2 17.5-12-5.9-5.2-13.7-8.4-22.1-8.4-2.8 0-5.4.3-7.9.9zM73.9 27c-.7 1-6.3 8.1-18.6 13.2.8 1.6 1.5 3.2 2.2 4.8.2.6.5 1.2.7 1.7 11.1-1.4 22.2.8 23.3 1.1-.2-7.9-3-15.2-7.6-20.8z'
  },
  {
    id: 'github',
    title: 'Github',
    url: 'https://github.com/du5rte',
    color: '#913399',
    path: 'M48 8.9C26.4 8.9 8.9 26.4 8.9 48c0 17.3 11.2 31.9 26.7 37.1 2 .3 2.7-.8 2.7-1.9v-7.3c-9.8 1.8-12.4-2.4-13.1-4.6-.4-1.1-2.3-4.6-4-5.5-1.4-.7-3.3-2.5 0-2.6 3.1 0 5.3 2.8 6 4 3.5 5.9 9.1 4.3 11.4 3.2.3-2.5 1.4-4.3 2.5-5.2-8.7-1-17.8-4.3-17.8-19.3 0-4.3 1.5-7.8 4-10.5-.4-1-1.8-5 .4-10.4 0 0 3.3-1 10.8 4 3.1-.9 6.5-1.3 9.8-1.3s6.6.4 9.8 1.3c7.5-5.1 10.8-4 10.8-4 2.2 5.4.8 9.4.4 10.4 2.5 2.7 4 6.2 4 10.5 0 15-9.1 18.3-17.8 19.3 1.4 1.2 2.6 3.6 2.6 7.2v10.8c0 1 .7 2.2 2.7 1.9C76.4 79.7 87.1 64.8 87.1 48 87.1 26.4 69.6 8.9 48 8.9z'
  }
]
